<template>
  <div>
    <!-- 시나리오 목록 -->
    <c-table
      ref="table"
      title="시나리오 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="false"
    >
      <template v-slot:customArea="{ props, col }">
        <component
          :is="imprComponent"
          :col="col"
          :props="props"
          :inputEditable="false"
          :requestContentsCols="requestContentsCols"
          tableKey="ramKpsrAssessScenarioId"
          ibmTaskTypeCd="ITT0000005"
          ibmTaskUnderTypeCd="ITTU000005"
          @imprChange="imprChange"
        />
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'kpsr-scenario-TL',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        merge: [
          { index: 0, colName: 'processName' },
          { index: 1, colName: 'processDiagram' },
          { index: 2, colName: 'processDiagramNode' },
          { index: 3, colName: 'processDiagramNodeRisk' },
        ],
        columns: [
          {
            fix: true,
            name: 'processName',
            field: 'processName',
            label: '공정',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            fix: true,
            name: 'diagramTitle',
            field: 'diagramTitle',
            description: 'diagramNo',
            // 도면
            label: '도면명',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'nodeTitle',
            field: 'nodeTitle',
            description: 'nodeNo',
            // 노드
            label: '노드',
            align: 'center',
            style: 'width:130px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskTypName',
            field: 'riskTypName',
            // 위험형태
            label: '위험형태',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceCause',
            field: 'riskOccurrenceCause',
            // 원인
            label: '원인',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'riskOccurrenceResult',
            field: 'riskOccurrenceResult',
            // 결과
            label: '결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: '관련법규',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'relationProblem',
            field: 'relationProblem',
            // 관련문제사항
            label: '관련문제사항',
            align: 'left',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: '현재안전조치',
            align: 'left',
            style: 'width:170px',
            sortable: false,
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: '평가일',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: '평가자',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'riskbookFlag',
            field: 'riskbookFlag',
            // 위험등록부
            label: '위험등록부',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: '개선진행',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: '개선번호/개선진행상태',
                align: 'center',
                style: 'width:130px',
                type: 'custom',
                sortable: false
              },
              {
                name: 'improvementMeasures',
                field: 'improvementMeasures',
                // 개선대책
                label: '개선대책',
                align: 'left',
                style: 'width:200px',
                sortable: false
              },
            ]
          },
        ],
        data: [],
        height: 'auto' //'600px'
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      requestImprRow: null,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    requestContentsCols() {
      return ['riskTypName', 'riskOccurrenceCause'];
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.ram.kpsr.scenario.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.param.ramRiskAssessmentPlanId,
      }
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
    linkClick(data, row) {
      this.requestImprRow = row
      this.popupOptions.title = '개선';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        if (result.col1.ibmStepCd === 'IS00000010' || result.col1.ibmStepCd === 'IS00000015' || result.col1.ibmStepCd === 'IS00000020') {
          this.requestImprRow.riskRegisterFlag = true
        }
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      }
    },
  }
};
</script>
