var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          title: "시나리오 목록",
          columns: _vm.grid.columns,
          gridHeight: _vm.grid.height,
          data: _vm.grid.data,
          merge: _vm.grid.merge,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: false,
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                _c(_vm.imprComponent, {
                  tag: "component",
                  attrs: {
                    col: col,
                    props: props,
                    inputEditable: false,
                    requestContentsCols: _vm.requestContentsCols,
                    tableKey: "ramKpsrAssessScenarioId",
                    ibmTaskTypeCd: "ITT0000005",
                    ibmTaskUnderTypeCd: "ITTU000005",
                  },
                  on: { imprChange: _vm.imprChange },
                }),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }